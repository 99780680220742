import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./css/swiper_styles.css";

// images from utils
import { clients } from "../utils/ImageGalleryUtils";
const SwiperImages = () => {
  return (
    <div className="swiper-main-container">
      <p>Our Partners</p>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        loop={true}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        speed={1000}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
      >
        {clients.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-img-container">
              <img src={item.image} alt="" className="img" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperImages;
