import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
// css imports
import "./css/footer.css";

// banner imports
import footer_banner from "../../assets/banner_img/footer_banner.png";
import { Grid } from "@mui/material";

// images imports
import gzone_energy_white_logo from "../../assets/gallery/company_logos/gzone_energy_applegreen_logo.png";

const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="footer-container-overlay"></div>
      <img src={footer_banner} alt="" className="img" />

      <div className="footer-details">
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 10 }}
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
          height="100%"
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid
              container
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 3 }}
              justifyContent="center"
              rowSpacing={1}
              height="350px"
            >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 3 }}
                  justifyContent="center"
                  rowSpacing={1}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="footer-company-logo-container alignment">
                      <div className="footer-logo-wrapper">
                        <img
                          src={gzone_energy_white_logo}
                          alt=""
                          className="img"
                        />
                      </div>
                      <div className="company-name-container ">
                        <p>GREENZONE</p>
                        <p> ENERGY</p>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="loc-contact-add-container alignment"
                  >
                    <CiLocationOn id="location_icon" />
                    <p>9037 Cozy Bldg. Pallocan West Batangas City</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="loc-contact-add-container alignment"
                  >
                    <BsTelephone />
                    <p>0912-3456-789</p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="loc-contact-add-container alignment"
                  >
                    <TfiEmail />
                    <p>greenzoneenergy@gmail.com</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <p className="footer-header">Services</p>
                <ul className="list">
                  <li>Solar Panel Installation</li>
                  <li>Solar Panel Maintenance</li>
                  <li>Wind Turbines Services</li>{" "}
                  <li>Battery Materials Services</li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <p className="footer-header">Product</p>

                <ul className="list">
                  <li>Solar Panels</li>
                  <li>Batteries</li>
                  <li>Lightings</li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <p className="footer-header">Quick Links</p>
                <ul className="list">
                  <li>About</li>
                  <li>Contacts</li>
                  <li>Location</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
