import React, { Suspense, lazy } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  HashRouter,
} from "react-router-dom";

import Navigationbar from "./components/navigationbar/NavBar";
import Homepage from "./pages/homepage/Homepage";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<Navigationbar />}>
              <Route path="/" element={<Homepage />} />
              {/* <Route path="/umspage" element={<UMSpage />} /> */}
              {/* <Route path="/loading" element={<LoadingAnimation />} /> */}
            </Route>
            {/* <Route path="/data" element={<DataStructure />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
