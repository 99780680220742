import React from "react";

// banner imports
import solar_panel_field from "../../../assets/banner_img/solar_panel_fields.jpg";
import { Grid } from "@mui/material";
import {
  card_images,
  advocates_details,
} from "../../../components/utils/ImageGalleryUtils";
import ReusableCards from "../../../components/cards/ReusableCards";
import CardStyle1 from "../../../components/cards/CardStyle1";

const Home3 = () => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <div className="section">
        <div className="overlay"></div>
        <div className="text-container">
          <p>We Advocates</p>
          <p>Embrace Renewable Energy For Sustainable Tomorrow</p>
        </div>

        <div className="img-container">
          <img src={solar_panel_field} alt="" className="img home3-banner" />
        </div>

        <div className="card-layout-container">
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 10 }}
            justifyContent={{ xs: "center", sm: "center", md: "center" }}
            rowSpacing={1}
          >
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid
                container
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 10 }}
                justifyContent={{ xs: "center", sm: "center", md: "center" }}
                rowSpacing={1}
              >
                {advocates_details.map((val, index) => {
                  const multiplier = 300 * index;
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="card-layout-warpper">
                        <CardStyle1
                          img={val.img}
                          card_title={val.card_title}
                          card_desc={val.card_desc}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default Home3;
