import React from "react";
import PageBanner from "../../components/pagebanners/PageBanner";
import { Grid } from "@mui/material";
import AOS from "aos";
import HomePageSection2 from "./pageSections/HomePageSection2";
import Home3 from "./pageSections/Home3";
import SwiperImages from "../../components/gallery/SwiperImages";

const Homepage = () => {
  AOS.init();

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 3, sm: 1, md: 3 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div>
          <PageBanner />
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 3, sm: 1, md: 3 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          marginTop="150px"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <HomePageSection2 />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Home3 />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SwiperImages />
      </Grid>
    </Grid>
  );
};

export default Homepage;
