import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const NavHook = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const hadleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleClick = (route) => {
    navigate(route.route);
    setIsDrawerOpen(false);
    setActiveItem(route);
  };

  return {
    hadleDrawerOpen,
    handleDrawerClose,
    isDrawerOpen,
    handleClick,
    activeItem,
  };
};

export default NavHook;
