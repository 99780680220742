import React from "react";

// css
import "./css/reusablecard.css";
import AOS from "aos";

const ReusableCards = (props) => {
  AOS.init({
    // once: true,
  });
  return (
    <>
      <div
        className="card-main-container"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-delay={props.delay}
      >
        <div className="card-media-container">
          <img src={props.img} alt="" className="img" />
        </div>
        <p className="card-title">{props.title}</p>
        <p className="card-content"></p>
      </div>
    </>
  );
};

export default ReusableCards;
