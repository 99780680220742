import { PiLeafLight } from "react-icons/pi";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";
import { BsGlobeAsiaAustralia } from "react-icons/bs";
import { TbShip } from "react-icons/tb";
import { FaFlag } from "react-icons/fa6";
import { MdHotel } from "react-icons/md";

export const menulist = [
  {
    menu: "Home",
    route: "/",
    icon: <AiFillHome />,
  },

  {
    menu: "About",
    route: "/about",
    icon: <PiLeafLight />,
  },
  {
    menu: "Services",
    route: "/services",
    icon: <PiLeafLight />,
  },
  {
    menu: "Contacts",
    route: "/contacts",
    icon: <PiLeafLight />,
  },
];
