import { Grid } from "@mui/material";
import React from "react";
import "./css/section2.css";
import ButtonReusable from "../../../components/buttons/ButtonReusable";
import { MdArrowOutward } from "react-icons/md";
import Gallery from "../../../components/gallery/Gallery";
import ReusableCards from "../../../components/cards/ReusableCards";
import { card_images } from "../../../components/utils/ImageGalleryUtils";

const HomePageSection2 = () => {
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 10 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
    >
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Gallery />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div className="section-right-container">
          <p>Complete Commercial And Residential Solar Systems</p>
          <p className="section-tagline">
            We Are Pioneers In The World Of Solar & Renewable Energy!
          </p>
        </div>
        <div className="section-desc-container">
          <p>
            Providing sustainable green energy involves generating and utilizing
            energy in a way that minimizes environmental impact, reduces carbon
            emissions, and promotes long-term ecological balance. Here's a
            description for providing sustainable green energy
          </p>
          <p>
            The key to sustainability lies in the continuous pursuit of
            innovative technologies and practices that reduce our reliance on
            finite fossil fuels and mitigate the impact of climate change.
            Implementing energy-efficient technologies, adopting smart grid
            systems, and promoting energy conservation become integral
            components of a holistic approach toward achieving a sustainable
            energy future.
          </p>
        </div>

        <div className="banner-button-container">
          {/* <button className="banner-button">
                              Our Services
                            </button> */}

          <ButtonReusable
            buttonName="Read More"
            backgroundColor="var(--darkBgColor)"
            padding="16px 32px"
            borderRadius="0px"
            color="white"
            endIcon={<MdArrowOutward />}
            fullWidth
            hoverBackgroundColor="var(--darkHoverBgColor)"
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 10 }}
          justifyContent={{ xs: "center", sm: "center", md: "center" }}
          rowSpacing={1}
          marginTop="150px"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="center-container ">
              <p className="section-subtitle text-center">Our Services</p>
            </div>
            <p className="section-tagline  text-capital">
              Inexhaustible and free source of energy
            </p>
          </Grid>

          {card_images.map((val, index) => {
            const multiplier = 300 * index;
            return (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ReusableCards
                  img={val.img}
                  title={val.card_title}
                  delay={multiplier}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePageSection2;
