import { Grid } from "@mui/material";
import React from "react";

// page banner style
import "./css/pagebanner.css";

// images references from ImageGalleryUtils
import { banner_images } from "../utils/ImageGalleryUtils";

import right_img1 from "../../assets/banner_img/energia_image08.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

import { MdArrowOutward } from "react-icons/md";
import ButtonReusable from "../../components/buttons/ButtonReusable";

const PageBanner = () => {
  AOS.init();

  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 1 }}
      justifyContent="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="banner-container">
          {banner_images.map((item) => (
            <div className="banner-img-container" key={item.id}>
              <div className="text-content">
                <Grid
                  container
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 1 }}
                  justifyContent={{ xs: "center", sm: "center", md: "center" }}
                  rowSpacing={1}
                >
                  <Grid item xs={12} sm={12} md={9} lg={9} marginTop="100px">
                    <Grid
                      container
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      columnSpacing={{ xs: 3, sm: 1, md: 3, lg: 5 }}
                      justifyContent={{
                        xs: "center",
                        sm: "center",
                        md: "center",
                      }}
                      rowSpacing={1}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-anchor-placement="center-center"
                          className="text-tagline"
                        >
                          energize society using sustainable & renewable energy
                        </div>
                        <div
                          className="text-desc"
                          data-aos="fade-up"
                          data-aos-offset="300"
                          data-aos-duration="1500"
                        >
                          Our commitment to sustainable and renewable energy
                          sources not only powers homes and businesses but
                          propels us towards a more resilient and eco-friendly
                          tomorrow. Join us in harnessing the boundless energy
                          of nature, as we pave the way for a greener planet,
                          one innovation at a time.
                        </div>
                        <div className="buttons-container">
                          <div className="banner-icons">
                            {item.icon_list.map((val) => (
                              <p className="icon" key={val.id}>
                                {val.icon}
                              </p>
                            ))}
                          </div>
                          <div className="banner-button-container">
                            {/* <button className="banner-button">
                              Our Services
                            </button> */}

                            <ButtonReusable
                              buttonName="Our Services"
                              backgroundColor="var(--backgroundCcolor)"
                              padding="16px 32px"
                              borderRadius="0px"
                              color="white"
                              endIcon={<MdArrowOutward />}
                              fullWidth
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className="banner-right-container">
                          {/* <img src={right_img1} alt="" className="img" /> */}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div className="overlay"></div>
              <img src={item.img} alt={item.tagline} className="img" />
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default PageBanner;
