import React from "react";

const CardStyle1 = (props) => {
  return (
    <div className="c1-main-container">
      <div className="c1-main-wrapper">
        <div className="c1-img-container">
          <img src={props.img} alt="" className="img" />
        </div>
        <div className="advocate-text-container">
          <p>{props.card_title}</p>
          <p>{props.card_desc}</p>
        </div>
      </div>
    </div>
  );
};

export default CardStyle1;
