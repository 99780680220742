// images for the banner
import banner_img1 from "../../assets/banner_img/image1.jpg";

import { MdEnergySavingsLeaf } from "react-icons/md";
import { LiaSolarPanelSolid } from "react-icons/lia";
import { GiSolarPower } from "react-icons/gi";

import { PiGearThin } from "react-icons/pi";
import { PiLeafThin } from "react-icons/pi";
import banner_icon1 from "../../assets/icons/banner_icon1.png";

// images for the about gallery section2 of the homepage
import sample_image from "../../assets/gallery/sample_image.jpg";
import sample_image2 from "../../assets/gallery/sample_image2.jpg";
import sample_image3 from "../../assets/gallery/sample_image3.jpg";
import sample_image4 from "../../assets/gallery/sample_image4.jpg";
import sample_image5 from "../../assets/gallery/sample_image5.jpg";
import sample_image6 from "../../assets/gallery/sample_image6.jpg";
import sample_image7 from "../../assets/gallery/sample_image7.jpg";

// card images / media
import card_img1 from "../../assets/gallery/card_images/card_img1.png";
import card_img2 from "../../assets/gallery/card_images/card_img2.png";
import card_img3 from "../../assets/gallery/card_images/card_img3.png";

// images for clients
import alway from "../../assets/gallery/clients/always.png";
import batelec from "../../assets/gallery/clients/baletec.png";
import clarkl_electric from "../../assets/gallery/clients/clark_electric.png";
import cozy from "../../assets/gallery/clients/cozy.png";
import mmsc from "../../assets/gallery/clients/mmsc.png";
import greenzone from "../../assets/gallery/clients/greenzone.png";
import ngc_enterprise from "../../assets/gallery/clients/ngc_enterprice.png";
import ngc_express from "../../assets/gallery/clients/ngc_expres.png";
import usi from "../../assets/gallery/clients/usi.png";

// letter images
import e1 from "../../assets/gallery/letters/e1.png";
import e2 from "../../assets/gallery/letters/e2.png";

import g1 from "../../assets/gallery/letters/g1.png";
import n1 from "../../assets/gallery/letters/n1.png";
import r1 from "../../assets/gallery/letters/r2.png";

export const banner_images = [
  {
    id: 1,
    tagline: "",
    img: banner_img1,
    icon_list: [
      { id: 1, icon: <GiSolarPower /> },
      { id: 1, icon: <PiLeafThin /> },
      { id: 1, icon: <PiGearThin /> },
    ],
  },
];

export const about_gallery = [
  { image: sample_image },
  { image: sample_image2 },
  { image: sample_image3 },
  { image: sample_image4 },
  { image: sample_image5 },
  { image: sample_image6 },
  { image: sample_image7 },
];

export const card_images = [
  { card_title: "Solar Panels Services", card_desc: "", img: card_img1 },
  { card_title: "Wind Turbines Services", card_desc: "", img: card_img2 },
  { card_title: "Battery Materials Services", card_desc: "", img: card_img3 },
];

export const clients = [
  { image: alway },
  { image: batelec },
  { image: clarkl_electric },
  { image: cozy },
  { image: mmsc },
  { image: greenzone },
  { image: ngc_enterprise },
  { image: ngc_express },
  { image: usi },
  { image: alway },
  { image: batelec },
  { image: clarkl_electric },
  { image: cozy },
  { image: mmsc },
  { image: greenzone },
  { image: ngc_enterprise },
  { image: ngc_express },
  { image: usi },
];

export const advocates_details = [
  {
    card_title: "Green Energy Commitment",
    card_desc:
      "Green energy is not just a choice; it's a commitment to a sustainable future. Discover how renewable sources are reshaping the energy landscape.",
    img: g1,
  },
  // { card_title: "Wind Turbines Services", card_desc: "", img: r1 },
  {
    card_title: "Embrace Renewable Energy",
    card_desc:
      "Make a positive impact on the environment by adopting renewable energy solutions that reduce your carbon footprint and contribute to a healthier planet.",
    img: e1,
  },
  // { card_title: "Wind Turbines Services", card_desc: "", img: e2 },

  {
    card_title: "Net-Zero Polution",
    card_desc:
      "Navigate the journey to Net Zero with our insights on incorporating renewable energy sources, energy conservation, and sustainable practices for a more environmentally friendly existence.",
    img: n1,
  },
];
