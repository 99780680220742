import React from "react";

// css import
import "./css/gallery.css";
import { Grid } from "@mui/material";
import { about_gallery } from "../../components/utils/ImageGalleryUtils";

const Gallery = () => {
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      columnSpacing={{ xs: 3, sm: 1, md: 3 }}
      justifyContent={{ xs: "center", sm: "center", md: "center" }}
      rowSpacing={1}
      height="100%"
    >
      <>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 3, sm: 1, md: 1 }}
            justifyContent={{ xs: "center", sm: "center", md: "center" }}
            rowSpacing={1}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                className="gallery-img-container"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <img src={about_gallery[1].image} alt="" className="img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid
                container
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                columnSpacing={{ xs: 3, sm: 1, md: 1 }}
                justifyContent={{ xs: "center", sm: "center", md: "center" }}
                rowSpacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    columnSpacing={{ xs: 3, sm: 1, md: 1 }}
                    justifyContent={{
                      xs: "center",
                      sm: "center",
                      md: "center",
                    }}
                    rowSpacing={1}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div
                        className="gallery-img-container"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000"
                        data-aos-delay="300"
                      >
                        <img
                          src={about_gallery[2].image}
                          alt=""
                          className="img"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div
                        className="gallery-img-container"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="1000"
                        data-aos-delay="400"
                      >
                        <img
                          src={about_gallery[3].image}
                          alt=""
                          className="img"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className="gallery-img-container"
                    data-aos="fade-left"
                    data-aos-offset="500"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    <img src={about_gallery[4].image} alt="" className="img" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            columnSpacing={{ xs: 3, sm: 1, md: 1 }}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "center",
            }}
            rowSpacing={1}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                className="gallery-img-container"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <img src={about_gallery[5].image} alt="" className="img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                className="gallery-img-container"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <img src={about_gallery[6].image} alt="" className="img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                className="gallery-img-container"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img src={about_gallery[0].image} alt="" className="img" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

export default Gallery;
