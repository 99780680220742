import React, { useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { Outlet, useNavigate } from "react-router-dom";
import "./css/navstyle.css";
import "./css/res_nav.css";
import brand from "../../assets/gallery/company_logos/gzone_energy_white_logo.png";

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { FaBars, FaPhoneAlt } from "react-icons/fa";
import { menulist } from "../utils/NavUtils";
import { MdClose, MdEmail, MdLocationOn } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import NavHook from "./hooks/NavHook";
import Footer from "../footer/Footer";

const HideOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Slide appear={false} direction="down" in={isVisible}>
      {children}
    </Slide>
  );
};

const drawerWidth = "100%";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,

  justifyContent: "flex-start",
}));
const NavBar = (props) => {
  const { ...param } = NavHook();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          {/* <ReusableNav /> */}

          <CssBaseline />
          <HideOnScroll {...props}>
            <AppBar className="appbar">
              <Toolbar className="toolbar">
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12} md={10} lg={9}>
                    <div className="navigation_wrapper">
                      <div className="navigation_container">
                        <div className="navigation_logo alignment">
                          <div className="nav-brand-container">
                            <img
                              src={brand}
                              style={{ cursor: "pointer" }}
                              className="img"
                            />
                            <div className="nav-brand-text-container alignment">
                              <p>GREENZONE</p>
                              <p>ENERGY</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            isMobile
                              ? "navigation_nav-mobile"
                              : "navigation_nav"
                          }
                        >
                          {menulist.map((val, index) => (
                            <p
                              key={index}
                              onClick={() => param.handleClick(val.route)}
                              // className="menu_items"
                              className={`menu_items ${
                                val.route === param.activeItem ? "active" : ""
                              }`}
                            >
                              {val.menu}
                            </p>
                          ))}
                        </div>

                        <div className="email-contacts-container alignment">
                          <div className="contacts-wrapper alignment">
                            <BsTelephone />
                            <p>0912-345-6789</p>
                          </div>
                          <div className="email-wrapper alignment">
                            <TfiEmail />
                            <p>greenzoneenergy@gmail.com</p>
                          </div>
                        </div>
                        <div
                          className={
                            isMobile ? "menu_icon-mobile" : "menu_icon"
                          }
                        >
                          <IconButton onClick={param.hadleDrawerOpen}>
                            <FaBars className="actual-icon" />
                          </IconButton>
                        </div>
                      </div>

                      <div className="contacts-container"></div>
                      <div className="divider">
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "white",
                            opacity: 0.3,
                            height: "24px",
                            display: "flex",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
              {isMobile ? (
                <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      height: "100vh",
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={param.isDrawerOpen}
                >
                  <DrawerHeader>
                    <IconButton onClick={param.handleDrawerClose}>
                      {theme.direction === "rtl" ? <MdClose /> : <MdClose />}
                    </IconButton>
                  </DrawerHeader>
                  <Divider />
                  <List>
                    {menulist.map((item, index) => (
                      <ListItem key={item} disablePadding>
                        <ListItemButton onClick={() => param.handleClick(item)}>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.menu} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>

                  <Divider />

                  <div className="nav-img-container">
                    <div>
                      <img className="img" src="" alt="company logo" />
                    </div>
                  </div>
                  <div className="contact-no-mbl">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          color: "#044a67",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          gap: "8px",
                        }}
                      >
                        <FaPhoneAlt />
                        <p style={{ margin: 0 }}>(+63) 91 711 5111</p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          color: "#044a67",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                          gap: "8px",
                        }}
                      >
                        <MdEmail />

                        <p style={{ margin: 0 }}>louie@gzonetechph.com</p>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          color: "#044a67",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px",
                        }}
                      >
                        {/* <MdLocationOn /> */}

                        <p style={{ margin: 0 }}>
                          Spectrum Midway Ext. Alabang, Muntinlupa,PH
                        </p>
                      </div>
                    </div>
                  </div>
                </Drawer>
              ) : null}
            </AppBar>
          </HideOnScroll>
          <Outlet />

          <Toolbar />

          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default NavBar;
